/* eslint-disable */
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

import type { SlotId } from '../../config';
import type * as types from '../d';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  AdditionalData: { input: types.BetAdditionalData; output: types.BetAdditionalData };
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: string; output: string };
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: unknown; output: unknown };
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { input: unknown; output: unknown };
  /** Floats that will have a value of 0 or more. */
  NonNegativeFloat: { input: number; output: number };
  /** Integers that will have a value of 0 or more. */
  NonNegativeInt: { input: number; output: number };
  SlotId: { input: SlotId; output: SlotId };
  /** A field whose value is a generic Universally Unique Identifier: https://en.wikipedia.org/wiki/Universally_unique_identifier. */
  UUID: { input: string; output: string };
  /** Integers that will have a value of 0 or more. */
  UnsignedInt: { input: number; output: number };
  UserBonusData: { input: types.UserBonusData; output: types.UserBonusData };
  _Any: { input: never; output: never };
  _FieldSet: { input: never; output: never };
};

/**
 * #### Auth result data.
 *
 * ---
 * 🥈:beta
 */
export type AuthData = {
  /** Session id. Use it in `authorization` header. */
  sessionId: Scalars['String']['output'];
};

/**
 * ### Authenticate options.
 *
 * ---
 * 🥇:stable
 */
export type AuthInput = {
  /**
   * Integration client id.
   *
   * Use `00000000-0000-0000-0000-000000000000` for play-for-fun mode.
   */
  clientId: Scalars['ID']['input'];
  /**
   * User token.
   *
   * In play-for-fun mode pass amount of coins you need on your balance.
   */
  token: Scalars['String']['input'];
};

/**
 * ### Player bet.
 *
 * ---
 * 🥉:alpha
 */
export type Bet = {
  /**
   * Bet amount in coins per single line.
   *
   * Total amount will be count as this amount multiplied by lines amoumt.
   *
   * ---
   * 🥈:beta
   */
  coinAmount: Scalars['NonNegativeInt']['output'];
  /**
   * Coin value. Number in cents. E.g. $0.01 = 1, $1 = 100
   *
   * 📌 Default: `1`
   * ---
   * 🥈:beta
   */
  coinValue: Scalars['NonNegativeInt']['output'];
  /** Bet creation date. */
  createdAt: Scalars['DateTime']['output'];
  /** Aditional data. */
  data: Scalars['AdditionalData']['output'];
  /**
   * Bet id.
   *
   * ---
   * 🥇:stable
   */
  id: Scalars['ID']['output'];
  /** Line set. */
  lineSet: Maybe<LineSet>;
  /** Reel set. */
  reelSet: Maybe<ReelSet>;
  /** Reel set id. */
  reelSetId: Scalars['UUID']['output'];
  /** Bet result. */
  result: BetResult;
  /**
   * Slot id.
   *
   * ---
   * 🥉:alpha
   */
  slotId: Scalars['UUID']['output'];
  /** Bet status. */
  status: BetStatus;
  /** Bet update date. */
  updatedAt: Scalars['DateTime']['output'];
  /** User bonus. */
  userBonus: Maybe<UserBonus>;
  /** User bonus id. */
  userBonusId: Maybe<Scalars['UUID']['output']>;
};

export type BetBalance = {
  placed: UserBalance;
  settled: UserBalance;
};

/**
 * Bonus reward.
 *
 * ---
 * 🥉:alpha
 */
export type BetBonusReward = {
  userBonus: Maybe<UserBonus>;
  userBonusId: Scalars['UUID']['output'];
};

/**
 * Coins reward.
 *
 * ---
 * 🥉:alpha
 */
export type BetCoinReward = {
  amount: Scalars['NonNegativeInt']['output'];
};

export type BetFilter = {
  /** [Optional] Line set id. */
  lineSetId?: InputMaybe<Scalars['UUID']['input']>;
  /** [Optional] Slot id. */
  slotId?: InputMaybe<Scalars['UUID']['input']>;
  /** [Optional] User bonus id. */
  userBonusId?: InputMaybe<Scalars['UUID']['input']>;
};

export type BetResult = {
  /** Win reel positions. */
  reelPositions: Array<Maybe<Scalars['NonNegativeInt']['output']>>;
  /** Win coin amount. */
  winCoinAmount: Scalars['NonNegativeInt']['output'];
};

/**
 * Bet reward.
 *
 * ---
 * 🥉:alpha
 */
export type BetReward = BetBonusReward | BetCoinReward;

export type BetStatus = 'NEW' | 'PENDING' | 'SETTLED';

export type BetsPage = IConnection & {
  edges: Array<QueryBetEdge>;
  pageInfo: PageInfo;
};

/**
 * ### Bonus.
 *
 * ---
 * 🥉:alpha
 */
export type Bonus = {
  /** Bonus price in coins. */
  coinAmount: Scalars['NonNegativeInt']['output'];
  /** Bonus data. May be not very interesting for FE. */
  data: Maybe<Scalars['JSONObject']['output']>;
  /** Description. */
  description: Maybe<Scalars['String']['output']>;
  /**
   * Bonus id.
   *
   * ---
   * 🥇:stable
   */
  id: Scalars['ID']['output'];
  /** If true bonus could be purchased with coins. */
  purchasable: Scalars['Boolean']['output'];
  /**
   * Reel set id.
   *
   * ---
   * 🥈:beta
   */
  reelSetId: Maybe<Scalars['String']['output']>;
  /** Number of bonus rounds. */
  rounds: Scalars['NonNegativeInt']['output'];
  /**
   * Slot id.
   *
   * ---
   * 🥈:beta
   */
  slot: Maybe<Slot>;
  /**
   * Slot id.
   *
   * ---
   * 🥈:beta
   */
  slotId: Maybe<Scalars['String']['output']>;
  /** Bonus title. */
  title: Maybe<Scalars['String']['output']>;
  /**
   * Bonus type.
   *
   * ---
   * 🥈:beta
   */
  type: BonusType;
};

/**
 * Bonus type.
 *
 * ---
 * 🥉:alpha
 */
export type BonusType =
  /** Free bet round */
  | 'FREE_BET_ROUND'
  /** Freespin bonus. */
  | 'FREE_SPIN'
  /** Freespin bonus with cascade multiplier. */
  | 'FREE_SPIN_CASCADE_MUL'
  /** Freespin bonus with rounds played multiplier. */
  | 'FREE_SPIN_ROUND_MUL'
  /** Special game mode. */
  | 'GAME_MODE'
  /** Bonus has pre-determined rewards stated in bonus data. */
  | 'PRE_LOADED'
  /** Special round bonus. */
  | 'SPECIAL_ROUND';

export type BuyBonusesInput = {
  /** Coin amount. */
  coinAmount: Scalars['NonNegativeInt']['input'];
  /** Coin value. */
  coinValue: Scalars['NonNegativeInt']['input'];
  /** User bonus id. */
  id: Scalars['UUID']['input'];
  /** Line set id. If needed. */
  lineSetId?: InputMaybe<Scalars['UUID']['input']>;
};

export type ClientSlotAutoplayConditions = {
  stopIfBalanceDecreasesBy: Maybe<ClientSlotAutoplayStopIfBalanceDecreasesBy>;
  stopIfBalanceIncreasesBy: Maybe<ClientSlotAutoplayStopIfBalanceIncreasesBy>;
  stopIfFeatureIsWon: Maybe<ClientSlotAutoplayStopIfFeatureIsWon>;
  stopIfSingleWinExceeds: Maybe<ClientSlotAutoplayStopIfSingleWinExceeds>;
  stopOnAnyWin: Maybe<ClientSlotAutoplayStopOnAnyWin>;
};

export type ClientSlotAutoplaySettings = {
  conditions: Maybe<ClientSlotAutoplayConditions>;
  options: Maybe<Array<Maybe<Scalars['NonNegativeInt']['output']>>>;
};

export type ClientSlotAutoplayStopIfBalanceDecreasesBy = {
  enabled: Maybe<Scalars['Boolean']['output']>;
  multipliers: Maybe<Array<Maybe<Scalars['NonNegativeInt']['output']>>>;
};

export type ClientSlotAutoplayStopIfBalanceIncreasesBy = {
  enabled: Maybe<Scalars['Boolean']['output']>;
  multipliers: Maybe<Array<Maybe<Scalars['NonNegativeInt']['output']>>>;
};

export type ClientSlotAutoplayStopIfFeatureIsWon = {
  enabled: Maybe<Scalars['Boolean']['output']>;
};

export type ClientSlotAutoplayStopIfSingleWinExceeds = {
  enabled: Maybe<Scalars['Boolean']['output']>;
  multipliers: Maybe<Array<Maybe<Scalars['NonNegativeInt']['output']>>>;
};

export type ClientSlotAutoplayStopOnAnyWin = {
  enabled: Maybe<Scalars['Boolean']['output']>;
};

export type ClientSlotCoinAmountsSettings = {
  default: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  quick: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
};

export type ClientSlotCoinValueSettings = {
  code: Scalars['String']['output'];
  variants: Maybe<Array<Maybe<Scalars['NonNegativeInt']['output']>>>;
};

export type ClientSlotFeatureSettings = {
  enabled: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
};

export type ClientSlotSettings = {
  autoplay: Maybe<ClientSlotAutoplaySettings>;
  coinAmounts: ClientSlotCoinAmountsSettings;
  coinValues: Array<Maybe<ClientSlotCoinValueSettings>>;
  /** Customisations. */
  custom: Maybe<Scalars['JSON']['output']>;
  features: Array<Maybe<ClientSlotFeatureSettings>>;
};

export type CloseRoundsForOperatorsParams = {
  /** Operators to close rounds for. */
  operators: Array<Scalars['String']['input']>;
  /** Threshold in seconds after which round must be closed. */
  secondsToCloseRound: Scalars['NonNegativeInt']['input'];
  /** User Bonuses to close rounds for. */
  userBonuses?: InputMaybe<Array<Scalars['String']['input']>>;
};

/**
 * #### Symbol combo.
 *
 * ---
 * 🥉:alpha
 */
export type Combo = {
  /** Multiplier pattern. */
  pattern: Scalars['String']['output'];
  /** Payoff type. */
  payoffType: Maybe<PayoffType>;
  /** Rewards. */
  rewards: Array<Maybe<Reward>>;
  /** Combo type. */
  type: ComboType;
};

/**
 * #### Combo type.
 *
 * ---
 * 🥉:alpha
 */
export type ComboType =
  /** Exact line match */
  | 'EXACT_MATCH'
  /** X of kind type */
  | 'X_OF_KIND';

export type CronJobCloseFrbInput = {
  /** Calculate the payment */
  calculatePayment?: InputMaybe<Scalars['Boolean']['input']>;
  /** Expires at time */
  expiredAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Operator to close rounds for. */
  operatorId: Scalars['String']['input'];
  /** Secret key related to the job. */
  secretKey: Scalars['String']['input'];
};

export type CronJobInput = {
  /** Name of the job to run. */
  name: CronJobName;
  /** Parameters. */
  params?: InputMaybe<CloseRoundsForOperatorsParams>;
  /** Retry failed transactions params. */
  retryFailedTransactionsParams?: InputMaybe<RetryFailedTransactionsParams>;
  /** Secret key related to the job. */
  secretKey: Scalars['String']['input'];
};

export type CronJobName = 'CLOSE_ROUNDS_FOR_OPERATORS' | 'RETRY_FAILED_TRANSACTIONS';

export type CronJobResult = {
  status: Scalars['String']['output'];
};

/** External user reference. */
export type ExternalUser = {
  externalId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  userId: Scalars['UUID']['output'];
};

export type FreeBetCloseInput = {
  /** Calculate the payment */
  calculatePayment?: InputMaybe<Scalars['Boolean']['input']>;
  /** Id of user bonus from operator */
  externalBonusId?: InputMaybe<Scalars['String']['input']>;
  /** Id of user bonus */
  freeBetBonusId?: InputMaybe<Scalars['String']['input']>;
};

export type FreeBetInput = {
  /** Client or Operator ID, name or any of External ID's. */
  clientId: Scalars['String']['input'];
  /** Coin amount. */
  coinAmount: Scalars['NonNegativeInt']['input'];
  /** Coin value. */
  coinValue: Scalars['NonNegativeInt']['input'];
  /** Currency. */
  currency: Scalars['String']['input'];
  /** Expires at time */
  expiredAt: Scalars['DateTime']['input'];
  /** Additional External ID to associated created bonus with. */
  externalBonusId?: InputMaybe<Scalars['String']['input']>;
  /** External User ID (receives the bonus). */
  externalUserId: Scalars['String']['input'];
  /** Bonus ID of Free Bet. */
  freeBetBonusId: Scalars['UUID']['input'];
  /** Operator ID, name or any of External ID's. */
  operatorId?: InputMaybe<Scalars['String']['input']>;
  /** Amount of free spin rounds */
  rounds: Scalars['NonNegativeInt']['input'];
  /** Start time of the bonus */
  startTime: Scalars['DateTime']['input'];
};

export type FreeBetUserBonus = {
  /** Referenced Bonus. */
  bonus: Bonus;
  /** External User Bonus ID. */
  externalBonusId: Maybe<Scalars['String']['output']>;
  /** External User ID. */
  externalUserId: Scalars['String']['output'];
  /** Internal User Bonus ID. (userBonus.id) */
  freeBetBonusId: Scalars['String']['output'];
  /** Created User Bonus. */
  userBonus: UserBonus;
};

/**
 * Get bet by id.
 *
 * ---
 * 🥉:alpha
 */
export type GetBetInput = {
  /** Bet id. */
  id: Scalars['ID']['input'];
};

/**
 * Get user's bets.
 *
 * ---
 * 🥉:alpha
 */
export type GetBetsInput = {
  /** Cursor after which get bets. */
  after?: InputMaybe<Scalars['String']['input']>;
  /** Cursor before which get bets. */
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<BetFilter>;
  /** Number of items to take from the start. */
  first?: InputMaybe<Scalars['NonNegativeInt']['input']>;
  /** Number of items to take from the end. */
  last?: InputMaybe<Scalars['NonNegativeInt']['input']>;
};

export type GetBonusInput = {
  /** Filter by bonus id. */
  id: Scalars['UUID']['input'];
};

export type GetBonusesInput = {
  /** Filter purchasable only bonuses */
  purchasable?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter bonuses by Slot ID */
  slotId?: InputMaybe<Scalars['String']['input']>;
};

export type GetSlotInput = {
  /** Slot id. */
  id: Scalars['ID']['input'];
};

export type GetSlotsInput = {
  /**
   * Last entry id
   *
   * 📌 Default: `-1`
   */
  lastId?: InputMaybe<Scalars['Int']['input']>;
  /**
   * Max amount of entries on page
   *
   * 📌 Default: `30`
   */
  limit?: InputMaybe<Scalars['NonNegativeInt']['input']>;
};

export type GetUserBonusesInput = {
  /** Filter by Coin Amount */
  coinAmount?: InputMaybe<Scalars['Int']['input']>;
  /** User bonus id. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Slot id filter for user bonuses. If not provided returns bonuses from all slots. */
  slotId?: InputMaybe<Scalars['UUID']['input']>;
  /** Bonus status */
  status?: InputMaybe<UserBonusStatus>;
};

export type IConnection = {
  pageInfo: PageInfo;
};

export type IEdge = {
  cursor: Scalars['String']['output'];
};

/**
 * Icon type.
 *
 * ---
 * 🥉:alpha
 */
export type IconType =
  /** Destroyable type. player may fail to destroy it. */
  | 'DESTROYABLE'
  /** Empty type. Doesn't have winning combos. */
  | 'EMPTY'
  /** Mystery type. Replaced by any symbol. */
  | 'MYSTERY'
  /** Regular type. */
  | 'REGULAR'
  /** Scatter type. Selected lines doesn't count. */
  | 'SCATTER'
  /** Wild type. Replaces any other symbol. */
  | 'WILD';

export type LineSet = {
  coinAmountMultiplier: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  lines: Array<Scalars['UnsignedInt']['output']>;
  slotId: Scalars['String']['output'];
};

/** Avaliable mutations. */
export type Mutation = {
  /**
   * Authenticate user by provided clientId and token. Returns session id.
   *
   * ---
   * 🥇:stable; 🔓
   *
   * ---
   */
  auth: Maybe<AuthData>;
  /**
   * Purchase bonus.
   * ---
   * 🥉:beta; 🔒
   * ---
   */
  buyBonus: Maybe<UserBonus>;
  /** Cache Clear. */
  cacheClear: Scalars['Int']['output'];
  /**
   * Assign bonus to user manually.
   * ---
   * 🥉:beta; 🔒
   * ---
   */
  freeBet: FreeBetUserBonus;
  /**
   * Close free bet bonus.
   * ---
   * 🥉:beta; 🔒
   * ---
   */
  freeBetClose: FreeBetUserBonus;
  /**
   * Invalidate user session and logout.
   *
   * ---
   * 🥇:stable; 🔒
   *
   * ---
   */
  logout: Maybe<Scalars['Boolean']['output']>;
  /**
   * Place bet.
   *
   * ---
   * 🥈:beta; 🔒
   *
   * ---
   */
  placeBet: Maybe<PlaceBetResult>;
  /**
   * Runs a cron job.
   * ---
   * 🥉:alpha; 🔒
   * ---
   */
  runCronJob: CronJobResult;
  runCronJobCloseFrb: CronJobResult;
  version: VersionData;
};

/** Avaliable mutations. */
export type MutationAuthArgs = {
  input: AuthInput;
};

/** Avaliable mutations. */
export type MutationBuyBonusArgs = {
  input: BuyBonusesInput;
};

/** Avaliable mutations. */
export type MutationFreeBetArgs = {
  input: FreeBetInput;
};

/** Avaliable mutations. */
export type MutationFreeBetCloseArgs = {
  input: FreeBetCloseInput;
};

/** Avaliable mutations. */
export type MutationPlaceBetArgs = {
  input: PlaceBetInput;
};

/** Avaliable mutations. */
export type MutationRunCronJobArgs = {
  input: CronJobInput;
};

/** Avaliable mutations. */
export type MutationRunCronJobCloseFrbArgs = {
  input: CronJobCloseFrbInput;
};

export type PageInfo = {
  count: Scalars['Int']['output'];
  endCursor: Scalars['String']['output'];
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor: Scalars['String']['output'];
};

/**
 * ### Winning combination.
 *
 * ---
 * 🥉:alpha
 */
export type Payline = {
  /**
   * Win amount for current line in coins.
   *
   * ---
   * 🥈:beta
   */
  amount: Maybe<Scalars['NonNegativeInt']['output']>;
  /**
   * Line index. Will be null if win amount doesn't depend on a line.
   *
   * ---
   * 🥉:alpha
   */
  lineId: Maybe<Scalars['NonNegativeInt']['output']>;
  /**
   * Indexes of combo's winning icons (symbols).
   *
   * ---
   * 🥉:alpha
   */
  winPositions: Maybe<Array<Maybe<Scalars['NonNegativeInt']['output']>>>;
};

/**
 * Payoff type.
 *
 * ---
 * 🥉:alpha
 */
export type PayoffType =
  /** Adjacent reels. */
  | 'ADJACENT'
  /** Any order. */
  | 'ANY'
  /** Left to right. Default. */
  | 'LTR'
  /** Right to left. */
  | 'RTL';

/**
 * Place bet.
 *
 * ---
 * 🥈:beta
 */
export type PlaceBetInput = {
  /**
   * Base coin amount. Multiplied by lineset miltiplier.
   *
   * `total amount = coinAmount * lineset.multiplier`
   */
  coinAmount?: InputMaybe<Scalars['NonNegativeInt']['input']>;
  /** Coin value in user currency multiplied by 100. */
  coinValue?: InputMaybe<Scalars['NonNegativeInt']['input']>;
  /** Line set id. */
  lineSetId: Scalars['UUID']['input'];
  /** Slot id. */
  slotId: Scalars['UUID']['input'];
  /**
   * Timebudget. Defines min amount of time in milliseconds to receive balance update.
   *
   * 📌 Default: `3000`
   */
  timeBudget?: InputMaybe<Scalars['NonNegativeInt']['input']>;
  /** [Optional] User bonus id. */
  userBonusId?: InputMaybe<Scalars['UUID']['input']>;
};

export type PlaceBetResult = {
  /** User balance after bet, but before bet settlement */
  balance: BetBalance;
  /** User bet. */
  bet: Bet;
  /** Paylines */
  paylines: Array<Maybe<Payline>>;
  /** Randoms recorded as result of placing bet. */
  recordedRandoms: Maybe<Array<Maybe<Scalars['NonNegativeInt']['output']>>>;
  /** Bet rewards. */
  rewards: Maybe<Array<Maybe<BetReward>>>;
};

/** Avaliable queries. */
export type Query = {
  _service: _Service;
  autoSpinsAmount: Scalars['Int']['output'];
  autoSpinsLeft: Scalars['Int']['output'];
  autoSpinsStartBalance: Scalars['Int']['output'];
  /**
   * Get user's bet by id.
   *
   * ---
   * 🥉:alpha; 🔒
   *
   * ---
   */
  bet: Maybe<Bet>;
  betAmount: Scalars['Int']['output'];
  bets: Maybe<BetsPage>;
  /**
   * Get simplified version of bets for replay purposes.
   *
   * ---
   * 🥉:alpha; 🔒
   *
   * ---
   */
  betsByInitialRoundId: Maybe<Array<SimplifiedBet>>;
  /**
   * Get available bonuses by id.
   *
   * ---
   * 🥉:beta; 🔒
   *
   * ---
   */
  bonus: Maybe<Bonus>;
  /**
   * Get available bonuses in current game.
   *
   * ---
   * 🥉:beta; 🔒
   *
   * ---
   */
  bonuses: Maybe<Array<Maybe<Bonus>>>;
  freeBetInfo: FreeBetUserBonus;
  /**
   * Get user's info.
   *
   * ---
   * 🥈:beta; 🔓
   *
   * ---
   */
  getInfo: RequestInfo;
  /**
   * Check if user session is valid.
   *
   * ---
   * 🥇:stable; 🔓
   *
   * ---
   */
  isAuthenticated: Maybe<Scalars['Boolean']['output']>;
  isAutoSpins: Scalars['Boolean']['output'];
  isHistoryVisible: Scalars['Boolean']['output'];
  isOpenAutoplayPopup: Scalars['Boolean']['output'];
  isOpenBetSettingsPopup: Scalars['Boolean']['output'];
  isOpenHistoryPopup: Scalars['Boolean']['output'];
  isOpenInfoPopup: Scalars['Boolean']['output'];
  isSlotBusy: Scalars['Boolean']['output'];
  isSlotStopped: Scalars['Boolean']['output'];
  isSoundOn: Scalars['Boolean']['output'];
  isSpinInProgress: Scalars['Boolean']['output'];
  isStopOnAnyWin: Scalars['Boolean']['output'];
  isStopOnBalanceDecrease: Scalars['Boolean']['output'];
  isStopOnBalanceIncrease: Scalars['Boolean']['output'];
  isStopOnFeatureWin: Scalars['Boolean']['output'];
  isStopOnWinExceeds: Scalars['Boolean']['output'];
  isTurboSpin: Scalars['Boolean']['output'];
  /**
   * Replay bet history.
   *
   * ---
   * 🥈:beta; 🔒
   *
   * ---
   */
  replayBets: Maybe<ReplayBetsPage>;
  /**
   * Replay bet.
   *
   * ---
   * 🥈:beta; 🔒
   *
   * ---
   */
  replayRound: Maybe<ReplayBetResult>;
  /**
   * Get user bonuses for replay session.
   *
   * ---
   * 🥉:beta; 🔒
   *
   * ---
   */
  replayUserBonuses: Maybe<Array<Maybe<UserBonus>>>;
  /**
   * Get slot by id.
   *
   * ---
   * 🥉:beta; 🔒
   *
   * ---
   */
  slot: Maybe<Slot>;
  /**
   * Get available slots.
   *
   * ---
   * 🥉:beta; 🔒
   *
   * ---
   */
  slots: Maybe<Array<Maybe<Slot>>>;
  staticReplayBets: ReplayBetsResponse;
  stopOnBalanceDecrease: Scalars['Int']['output'];
  stopOnBalanceIncrease: Scalars['Int']['output'];
  stopOnWinExceeds: Scalars['Int']['output'];
  /**
   * Get user's information.
   *
   * ---
   * 🥈:beta; 🔒
   *
   * ---
   */
  user: User;
  /**
   * Get user bonuses.
   *
   * ---
   * 🥉:beta; 🔒
   *
   * ---
   */
  userBonuses: Maybe<Array<Maybe<UserBonus>>>;
  version: VersionData;
  winAmount: Scalars['Int']['output'];
};

/** Avaliable queries. */
export type QueryBetArgs = {
  input: GetBetInput;
};

/** Avaliable queries. */
export type QueryBetsArgs = {
  input: GetBetsInput;
};

/** Avaliable queries. */
export type QueryBetsByInitialRoundIdArgs = {
  initialRoundId: Scalars['UUID']['input'];
};

/** Avaliable queries. */
export type QueryBonusArgs = {
  input: InputMaybe<GetBonusInput>;
};

/** Avaliable queries. */
export type QueryBonusesArgs = {
  input: InputMaybe<GetBonusesInput>;
};

/** Avaliable queries. */
export type QueryFreeBetInfoArgs = {
  input: FreeBetCloseInput;
};

/** Avaliable queries. */
export type QueryReplayBetsArgs = {
  betId: Scalars['ID']['input'];
};

/** Avaliable queries. */
export type QueryReplayRoundArgs = {
  betId: Scalars['ID']['input'];
};

/** Avaliable queries. */
export type QueryReplayUserBonusesArgs = {
  betId: Scalars['ID']['input'];
};

/** Avaliable queries. */
export type QuerySlotArgs = {
  input: GetSlotInput;
};

/** Avaliable queries. */
export type QuerySlotsArgs = {
  input: GetSlotsInput;
};

/** Avaliable queries. */
export type QueryStaticReplayBetsArgs = {
  input: StaticReplayQueryInput;
};

/** Avaliable queries. */
export type QueryUserBonusesArgs = {
  input: InputMaybe<GetUserBonusesInput>;
};

export type QueryBetEdge = IEdge & {
  cursor: Scalars['String']['output'];
  node: Bet;
};

export type QueryReplayBetEdge = IEdge & {
  cursor: Scalars['String']['output'];
  node: ReplayBet;
};

/**
 * #### Reward type.
 *
 * ---
 * 🥉:alpha
 */
export type Reward_Type =
  /** Bonus reward. Bonus added to user bonuses */
  | 'BONUS'
  /** Coins reward */
  | 'COINS'
  /** Random reward. */
  | 'RANDOM_REWARD';

/**
 * #### Reelset.
 * ---
 * 🥉:alpha
 */
export type ReelSet = {
  /** Additional reel set layout. */
  additionalReelSets: Maybe<Array<Maybe<Array<Maybe<Scalars['String']['output']>>>>>;
  /** Reel layout columns. */
  cols: Maybe<Scalars['NonNegativeInt']['output']>;
  /** Reel id. */
  id: Scalars['ID']['output'];
  /** Reel layout. */
  layout: Array<Array<Scalars['SlotId']['output']>>;
  /** Reel layout rows. */
  rows: Maybe<Scalars['NonNegativeInt']['output']>;
  /** Reel type. */
  type: Maybe<ReelSetType>;
};

/**
 * Reel set type.
 *
 * ---
 * 🥉:alpha
 */
export type ReelSetType =
  /** Bonus reel set. */
  | 'BONUS'
  /** Default reel. */
  | 'DEFAULT';

export type RelatedBet = {
  originalBetId: Scalars['UUID']['output'];
  related: Maybe<Array<StaticReplayBet>>;
};

export type ReplayBet = {
  /**
   * Bet amount in coins per single line.
   *
   * Total amount will be count as this amount multiplied by lines amoumt.
   *
   * ---
   * 🥈:beta
   */
  coinAmount: Scalars['NonNegativeInt']['output'];
  /**
   * Coin value. Number in cents. E.g. $0.01 = 1, $1 = 100
   *
   * 📌 Default: `1`
   * ---
   * 🥈:beta
   */
  coinValue: Scalars['NonNegativeInt']['output'];
  /** Bet creation date. */
  createdAt: Scalars['DateTime']['output'];
  /** Aditional data. */
  data: Scalars['AdditionalData']['output'];
  /**
   * Bet id.
   *
   * ---
   * 🥇:stable
   */
  id: Scalars['ID']['output'];
  /** Line set. */
  lineSet: Maybe<LineSet>;
  /** Reel set. */
  reelSet: Maybe<ReelSet>;
  /** Reel set id. */
  reelSetId: Scalars['UUID']['output'];
  /** User bonus. */
  replayUserBonus: Maybe<UserBonus>;
  /** Bet result. */
  result: BetResult;
  /**
   * Slot id.
   *
   * ---
   * 🥉:alpha
   */
  slotId: Scalars['UUID']['output'];
  /** Bet status. */
  status: BetStatus;
  /** Bet update date. */
  updatedAt: Scalars['DateTime']['output'];
  /** User bonus id. */
  userBonusId: Maybe<Scalars['UUID']['output']>;
};

export type ReplayBetResult = {
  /** User balance after bet, but before bet settlement */
  balance: BetBalance;
  /** User bet. */
  bet: ReplayBet;
  /** Paylines */
  paylines: Array<Maybe<Payline>>;
  /** Randoms recorded as result of placing bet. */
  recordedRandoms: Maybe<Array<Maybe<Scalars['NonNegativeInt']['output']>>>;
  /** Bet rewards. */
  rewards: Maybe<Array<Maybe<ReplayBetReward>>>;
};

/**
 * Replay bet reward.
 *
 * ---
 * 🥉:alpha
 */
export type ReplayBetReward = BetCoinReward | ReplayBonusReward;

export type ReplayBetsPage = IConnection & {
  edges: Array<QueryReplayBetEdge>;
  pageInfo: PageInfo;
};

export type ReplayBetsResponse = {
  betResults: Maybe<Scalars['JSON']['output']>;
  bets: Maybe<Array<StaticReplayBet>>;
  bonusTransactions: Maybe<Scalars['JSON']['output']>;
  isBuyFeature: Scalars['Boolean']['output'];
  nextBetIds: Maybe<Scalars['JSON']['output']>;
  relatedBets: Maybe<Array<RelatedBet>>;
  /** Bets. */
  slot: StaticReplaySlot;
  transactions: Maybe<Scalars['JSON']['output']>;
};

/**
 * Bonus reward.
 *
 * ---
 * 🥉:alpha
 */
export type ReplayBonusReward = {
  replayUserBonus: Maybe<UserBonus>;
  userBonusId: Scalars['UUID']['output'];
};

/**
 * ### Request info.
 *
 * Just for debugging.
 *
 * ---
 * 🥈:beta
 */
export type RequestInfo = {
  /** User city. */
  city: Maybe<Scalars['String']['output']>;
  /** User country code. */
  country: Maybe<Scalars['String']['output']>;
  /** Request id. */
  id: Maybe<Scalars['ID']['output']>;
  /** User IP. */
  ip: Maybe<Scalars['String']['output']>;
  /** Language. */
  language: Maybe<Scalars['String']['output']>;
  /** User agent. */
  userAgent: Maybe<Scalars['String']['output']>;
};

export type ResponseMetadata = {
  data: Scalars['JSONObject']['output'];
  type: Scalars['String']['output'];
};

export type Result = {
  changeTo: Maybe<Scalars['String']['output']>;
  reelPositions: Maybe<Array<Scalars['Int']['output']>>;
  winAmount: Scalars['NonNegativeInt']['output'];
};

export type RetryFailedTransactionsParams = {
  /** Transactions to retry. */
  transactions: Array<Transaction>;
};

/**
 * #### Coin reward type.
 *
 * ---
 * 🥉:alpha
 */
export type Reward = {
  /** Bonus id. */
  bonusId: Maybe<Scalars['UUID']['output']>;
  /** Bonus count. */
  count: Maybe<Scalars['Int']['output']>;
  /** Combo's multiplier. */
  multiplier: Maybe<Scalars['Int']['output']>;
  /** Random rewards. */
  rewards: Maybe<Array<Maybe<Reward>>>;
  /** Reward type. */
  type: Reward_Type;
};

/**
 * ### Simplified bet.
 *
 * ---
 * 🥉:alpha
 */
export type SimplifiedBet = {
  /**
   * Bet id.
   *
   * ---
   * 🥇:stable
   */
  id: Scalars['ID']['output'];
  /** Reel set id. */
  reelSetId: Scalars['UUID']['output'];
  /** Bet result. */
  result: BetResult;
};

export type Slice = {
  end?: InputMaybe<Scalars['Int']['input']>;
  start?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * ### Slot config.
 *
 * May contain additional stuff.
 *
 * ---
 * 🥉:alpha
 */
export type Slot = {
  /** Slot client settings */
  clientSettings: Maybe<ClientSlotSettings>;
  icons: Maybe<Array<Maybe<SlotIcon>>>;
  /**
   * Slot id.
   *
   * ---
   * 🥇:stable
   */
  id: Scalars['ID']['output'];
  lineSets: Maybe<Array<Maybe<LineSet>>>;
  /**
   * Avalable lines configs.
   * Line id = line index in array.
   *
   * ```
   *
   * |----|----|----|----|----|
   * |  0 |  1 |  2 |  3 |  4 |
   * |----|----|----|----|----|
   * |  5 |  6 |  7 |  8 |  9 |
   * |----|----|----|----|----|
   * | 10 | 11 | 12 | 13 | 14 |
   * |----|----|----|----|----|
   * ```
   *
   * ---
   * 🥉:alpha
   */
  lines: Maybe<Array<Maybe<Array<Maybe<Scalars['Int']['output']>>>>>;
  /** Game preview image */
  previewImage: Maybe<Scalars['String']['output']>;
  /**
   * Reels configuration.
   *
   * Parent array contains reel. Index - number of reel.
   *
   * Nested arrays contain symbols on a reel. Index - number of symbol on reel.
   *
   * ---
   * 🥉:alpha
   */
  reels: Maybe<Array<Maybe<ReelSet>>>;
  /** Slot settings */
  settings: Maybe<SlotSettings>;
};

export type SlotBetLinesSettings = {
  max: Maybe<Scalars['Int']['output']>;
  min: Maybe<Scalars['Int']['output']>;
};

/**
 * #### Reel symbol with combinations and multipliers.
 *
 * ---
 * 🥉:alpha
 */
export type SlotIcon = {
  /** Available combos. */
  combos: Maybe<Array<Maybe<Combo>>>;
  /**
   * Icon id (aka code).
   *
   * Example: `A`, `SC1`.
   */
  id: Scalars['SlotId']['output'];
  /** Icon type. */
  type: IconType;
};

export type SlotSettings = {
  betLines: Maybe<SlotBetLinesSettings>;
  startPosition: Maybe<Array<Maybe<Scalars['NonNegativeInt']['output']>>>;
};

export type StaticReplayBet = {
  coinAmount: Scalars['NonNegativeInt']['output'];
  coinValue: Scalars['NonNegativeInt']['output'];
  createdAt: Scalars['DateTime']['output'];
  data: Maybe<Scalars['JSON']['output']>;
  id: Scalars['ID']['output'];
  lineSet: Maybe<LineSet>;
  reelSet: Maybe<ReelSet>;
  reelSetId: Scalars['UUID']['output'];
  result: BetResult;
  slotId: Scalars['UUID']['output'];
  status: BetStatus;
  updatedAt: Scalars['DateTime']['output'];
  userBonus: Maybe<StaticReplayUserBonus>;
  userBonusId: Maybe<Scalars['UUID']['output']>;
};

export type StaticReplayQueryInput = {
  betId: Scalars['UUID']['input'];
  expandFreeSpins: Scalars['Boolean']['input'];
};

export type StaticReplaySlot = {
  icons: Array<SlotIcon>;
  id: Scalars['ID']['output'];
  lineSets: Maybe<Array<Maybe<LineSet>>>;
  lines: Maybe<Array<Maybe<Array<Maybe<Scalars['Int']['output']>>>>>;
  reels: Maybe<Array<Maybe<ReelSet>>>;
};

export type StaticReplayUserBonus = {
  balance: Maybe<UserBalance>;
  bonusId: Scalars['UUID']['output'];
  cascadeMultiplier: Maybe<Scalars['NonNegativeInt']['output']>;
  coinAmount: Maybe<Scalars['NonNegativeInt']['output']>;
  coinValue: Maybe<Scalars['NonNegativeInt']['output']>;
  createdAt: Scalars['DateTime']['output'];
  data: Maybe<Scalars['JSONObject']['output']>;
  id: Scalars['ID']['output'];
  rounds: Scalars['NonNegativeInt']['output'];
  roundsPlayed: Scalars['NonNegativeInt']['output'];
  status: UserBonusStatus;
  totalWinAmount: Scalars['NonNegativeInt']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

/** Avaliable subscriptions. */
export type Subscription = {
  /**
   * Get notification once user's balance is updated.
   *
   * ---
   * 🥈:beta; 🔒
   *
   * ---
   */
  balanceUpdated: UserBalance;
  version: VersionData;
};

export type Transaction = {
  transactionId: Scalars['String']['input'];
  verifySumOfCreditsAmount: Scalars['Boolean']['input'];
};

/**
 * ### User info.
 *
 * ---
 * 🥈:beta
 */
export type User = {
  /** User's balance info. */
  balance: Maybe<UserBalance>;
  /** Client id that was passed in auth. */
  clientId: Scalars['UUID']['output'];
  /** Internal user id. */
  id: Scalars['ID']['output'];
};

/**
 * #### User balance info.
 *
 * ---
 * 🥈:beta
 */
export type UserBalance = {
  /** User balance in provided currency * 100 */
  amount: Scalars['NonNegativeFloat']['output'];
  /** User currency code */
  currency: Scalars['String']['output'];
  metadata: Maybe<ResponseMetadata>;
};

export type UserBonus = {
  /** Balance data (in buyBonus) */
  balance: Maybe<UserBalance>;
  /**
   * Bet. If user bonus was triggered by bet.
   *
   * ---
   * 🥇:stable
   */
  bet: Maybe<Bet>;
  /**
   * Bet id. If user bonus was triggered by bet.
   *
   * ---
   * 🥇:stable
   */
  betId: Maybe<Scalars['UUID']['output']>;
  /**
   * Bonus.
   *
   * ---
   * 🥇:stable
   */
  bonus: Maybe<Bonus>;
  /**
   * Bonus id. Maybe replace with bonus.
   *
   * ---
   * 🥇:stable
   */
  bonusId: Scalars['UUID']['output'];
  /**
   * User bonus chain
   *
   * ---
   * *** VERY HEAVY QUERY ***
   */
  bonusesChain: Maybe<Array<Maybe<UserBonus>>>;
  /** Cascade multiplier value. */
  cascadeMultiplier: Maybe<Scalars['NonNegativeInt']['output']>;
  /** Coin amount for current bonus, if applicable */
  coinAmount: Maybe<Scalars['NonNegativeInt']['output']>;
  /** Coin value for current bonus, if applicable */
  coinValue: Maybe<Scalars['NonNegativeInt']['output']>;
  /** User bonus creation datetime */
  createdAt: Scalars['DateTime']['output'];
  /** Currency. */
  currency: Maybe<Scalars['String']['output']>;
  /** Some json stuff */
  data: Scalars['UserBonusData']['output'];
  /** User bonus expiration date (Free Bet) */
  expiredAt: Maybe<Scalars['String']['output']>;
  /** External Bonus ID. */
  externalBonusId: Maybe<Scalars['String']['output']>;
  /** Date when Financial Round is started. */
  financialRoundStartedAt: Maybe<Scalars['DateTime']['output']>;
  /**
   * User bonus id.
   *
   * ---
   * 🥇:stable
   */
  id: Scalars['ID']['output'];
  /** UserBonus was created from Free Bet. */
  isFreeBet: Maybe<Scalars['Boolean']['output']>;
  /** Line set. */
  lineSet: Maybe<LineSet>;
  /** Number of remaining bonus rounds. */
  rounds: Scalars['NonNegativeInt']['output'];
  /** Number of bonus rounds already played. */
  roundsPlayed: Scalars['NonNegativeInt']['output'];
  /** User bonus promotion starting date (Free Bet) */
  startTime: Maybe<Scalars['DateTime']['output']>;
  /**
   * User bonus status
   *
   * 📌 Default: `30`
   */
  status: UserBonusStatus;
  /** Total win amount in coins */
  totalWinAmount: Scalars['NonNegativeInt']['output'];
  /** User bonus last update time */
  updatedAt: Scalars['DateTime']['output'];
};

export type UserBonusBonusesChainArgs = {
  slice: InputMaybe<Slice>;
};

export type UserBonusStatus = 'ACTIVE' | 'INACTIVE' | 'SETTLED';

export type VersionData = {
  time: Scalars['String']['output'];
  version: Scalars['String']['output'];
};

export type _Service = {
  sdl: Maybe<Scalars['String']['output']>;
};

export type UserBonusFragment = {
  id: string;
  coinValue: number | null;
  coinAmount: number | null;
  data: types.UserBonusData;
  betId: string | null;
  totalWinAmount: number;
  rounds: number;
  roundsPlayed: number;
  bonusId: string;
  status: UserBonusStatus;
  isFreeBet: boolean | null;
  bonus: { id: string; reelSetId: string | null; type: BonusType; coinAmount: number } | null;
} & { ' $fragmentName'?: 'UserBonusFragment' };

export type HistoryNodeFragment = {
  id: string;
  coinAmount: number;
  coinValue: number;
  reelSetId: string;
  data: types.BetAdditionalData;
  createdAt: string;
  updatedAt: string;
  result: { reelPositions: Array<number | null>; winCoinAmount: number };
  userBonus: { betId: string | null; bonusId: string; bonus: { coinAmount: number } | null } | null;
} & { ' $fragmentName'?: 'HistoryNodeFragment' };

export type BetCoinRewardFragment = { amount: number } & { ' $fragmentName'?: 'BetCoinRewardFragment' };

export type BetBonusRewardFragment = {
  userBonusId: string;
  userBonus: { ' $fragmentRefs'?: { UserBonusFragment: UserBonusFragment } } | null;
} & { ' $fragmentName'?: 'BetBonusRewardFragment' };

export type ReplayBonusRewardFragment = {
  userBonusId: string;
  userBonus: { ' $fragmentRefs'?: { UserBonusFragment: UserBonusFragment } } | null;
} & { ' $fragmentName'?: 'ReplayBonusRewardFragment' };

export type AuthMutationVariables = Exact<{
  input: AuthInput;
}>;

export type AuthMutation = { auth: { sessionId: string } | null };

export type PlaceBetMutationVariables = Exact<{
  input: PlaceBetInput;
}>;

export type PlaceBetMutation = {
  placeBet: {
    bet: {
      id: string;
      coinAmount: number;
      coinValue: number;
      slotId: string;
      status: BetStatus;
      userBonusId: string | null;
      reelSetId: string;
      data: types.BetAdditionalData;
      createdAt: string;
      updatedAt: string;
      reelSet: { id: string; layout: Array<Array<SlotId>>; type: ReelSetType | null } | null;
      lineSet: { id: string; coinAmountMultiplier: number } | null;
      userBonus: { bonus: { reelSetId: string | null } | null } | null;
      result: { reelPositions: Array<number | null>; winCoinAmount: number };
    };
    paylines: Array<{ lineId: number | null; winPositions: Array<number | null> | null; amount: number | null } | null>;
    balance: { placed: { amount: number; currency: string }; settled: { amount: number; currency: string } };
    rewards: Array<
      | ({ __typename: 'BetBonusReward' } & { ' $fragmentRefs'?: { BetBonusRewardFragment: BetBonusRewardFragment } })
      | ({ __typename: 'BetCoinReward' } & { ' $fragmentRefs'?: { BetCoinRewardFragment: BetCoinRewardFragment } })
      | null
    > | null;
  } | null;
};

export type BuyBonusMutationVariables = Exact<{
  input: BuyBonusesInput;
}>;

export type BuyBonusMutation = {
  buyBonus: {
    id: string;
    bonusId: string;
    status: UserBonusStatus;
    coinValue: number | null;
    data: types.UserBonusData;
  } | null;
};

export type ConfigQueryVariables = Exact<{ [key: string]: never }>;

export type ConfigQuery = {
  isTurboSpin: boolean;
  isSoundOn: boolean;
  isSpinInProgress: boolean;
  isSlotBusy: boolean;
  isSlotStopped: boolean;
  isOpenHistoryPopup: boolean;
  isOpenBetSettingsPopup: boolean;
  isOpenAutoplayPopup: boolean;
  isOpenInfoPopup: boolean;
  isHistoryVisible: boolean;
};

export type GetUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserQuery = {
  user: { id: string; clientId: string; balance: { amount: number; currency: string } | null };
};

export type ReplayGetUserQueryVariables = Exact<{ [key: string]: never }>;

export type ReplayGetUserQuery = {
  user: { id: string; clientId: string; balance: { amount: number; currency: string } | null };
};

export type GetBetQueryVariables = Exact<{ [key: string]: never }>;

export type GetBetQuery = { betAmount: number };

export type GetWinQueryVariables = Exact<{ [key: string]: never }>;

export type GetWinQuery = { winAmount: number };

export type GetAutoSpinsSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAutoSpinsSettingsQuery = {
  autoSpinsAmount: number;
  stopOnWinExceeds: number;
  stopOnBalanceIncrease: number;
  stopOnBalanceDecrease: number;
  isStopOnAnyWin: boolean;
  isStopOnFeatureWin: boolean;
  isStopOnWinExceeds: boolean;
  isStopOnBalanceIncrease: boolean;
  isStopOnBalanceDecrease: boolean;
  autoSpinsStartBalance: number;
};

export type GetAutoSpinQueryVariables = Exact<{ [key: string]: never }>;

export type GetAutoSpinQuery = { isAutoSpins: boolean; autoSpinsLeft: number };

export type UserBonusBetsQueryVariables = Exact<{
  input: GetBetsInput;
}>;

export type UserBonusBetsQuery = { bets: { pageInfo: { count: number } } | null };

export type ReplayBonusBetsQueryVariables = Exact<{
  input: GetBetsInput;
}>;

export type ReplayBonusBetsQuery = { bets: { pageInfo: { count: number } } | null };

export type SlotHistoryQueryVariables = Exact<{
  input: GetBetsInput;
}>;

export type SlotHistoryQuery = {
  bets: {
    pageInfo: { count: number; hasPreviousPage: boolean; hasNextPage: boolean; startCursor: string; endCursor: string };
    edges: Array<{ cursor: string; node: { ' $fragmentRefs'?: { HistoryNodeFragment: HistoryNodeFragment } } }>;
  } | null;
};

export type ReplaySlotHistoryQueryVariables = Exact<{
  input: GetBetsInput;
}>;

export type ReplaySlotHistoryQuery = {
  bets: {
    pageInfo: { count: number; hasPreviousPage: boolean; hasNextPage: boolean; startCursor: string; endCursor: string };
    edges: Array<{ cursor: string; node: { ' $fragmentRefs'?: { HistoryNodeFragment: HistoryNodeFragment } } }>;
  } | null;
};

export type BetQueryVariables = Exact<{
  input: GetBetInput;
}>;

export type BetQuery = {
  bet: {
    id: string;
    coinAmount: number;
    coinValue: number;
    slotId: string;
    status: BetStatus;
    reelSetId: string;
    data: types.BetAdditionalData;
    result: { reelPositions: Array<number | null>; winCoinAmount: number };
    userBonus: { betId: string | null } | null;
  } | null;
};

export type GetSlotQueryVariables = Exact<{
  input: GetSlotInput;
}>;

export type GetSlotQuery = {
  slot: {
    id: string;
    lines: Array<Array<number | null> | null> | null;
    reels: Array<{ id: string; type: ReelSetType | null; layout: Array<Array<SlotId>> } | null> | null;
    icons: Array<{ id: SlotId } | null> | null;
    settings: { startPosition: Array<number | null> | null } | null;
    clientSettings: {
      coinAmounts: { default: Array<number | null> | null; quick: Array<number | null> | null };
      coinValues: Array<{ code: string; variants: Array<number | null> | null } | null>;
      autoplay: {
        options: Array<number | null> | null;
        conditions: {
          stopOnAnyWin: { enabled: boolean | null } | null;
          stopIfFeatureIsWon: { enabled: boolean | null } | null;
          stopIfSingleWinExceeds: { enabled: boolean | null; multipliers: Array<number | null> | null } | null;
          stopIfBalanceDecreasesBy: { enabled: boolean | null; multipliers: Array<number | null> | null } | null;
          stopIfBalanceIncreasesBy: { enabled: boolean | null; multipliers: Array<number | null> | null } | null;
        } | null;
      } | null;
    } | null;
    lineSets: Array<{ id: string; lines: Array<number>; coinAmountMultiplier: number } | null> | null;
  } | null;
};

export type GetBonusesQueryVariables = Exact<{
  input: InputMaybe<GetBonusesInput>;
}>;

export type GetBonusesQuery = { bonuses: Array<{ id: string; type: BonusType; coinAmount: number } | null> | null };

export type GetUserBonusesQueryVariables = Exact<{
  input: InputMaybe<GetUserBonusesInput>;
}>;

export type GetUserBonusesQuery = {
  userBonuses: Array<{ ' $fragmentRefs'?: { UserBonusFragment: UserBonusFragment } } | null> | null;
};

export type GetReplayUserBonusesQueryVariables = Exact<{
  input: InputMaybe<GetUserBonusesInput>;
}>;

export type GetReplayUserBonusesQuery = {
  userBonuses: Array<{ ' $fragmentRefs'?: { UserBonusFragment: UserBonusFragment } } | null> | null;
};

export type ReplayBetQueryVariables = Exact<{
  betId: Scalars['ID']['input'];
}>;

export type ReplayBetQuery = {
  placeBet: {
    bet: {
      id: string;
      coinAmount: number;
      coinValue: number;
      slotId: string;
      status: BetStatus;
      userBonusId: string | null;
      reelSetId: string;
      data: types.BetAdditionalData;
      createdAt: string;
      updatedAt: string;
      reelSet: { id: string; layout: Array<Array<SlotId>>; type: ReelSetType | null } | null;
      lineSet: { id: string; coinAmountMultiplier: number } | null;
      userBonus: { bonus: { reelSetId: string | null } | null } | null;
      result: { reelPositions: Array<number | null>; winCoinAmount: number };
    };
    paylines: Array<{ lineId: number | null; winPositions: Array<number | null> | null; amount: number | null } | null>;
    balance: { placed: { amount: number; currency: string }; settled: { amount: number; currency: string } };
    rewards: Array<
      | ({ __typename: 'BetCoinReward' } & { ' $fragmentRefs'?: { BetCoinRewardFragment: BetCoinRewardFragment } })
      | ({ __typename: 'ReplayBonusReward' } & {
          ' $fragmentRefs'?: { ReplayBonusRewardFragment: ReplayBonusRewardFragment };
        })
      | null
    > | null;
  } | null;
};

export type BetsByInitialRoundIdQueryVariables = Exact<{
  initialRoundId: Scalars['UUID']['input'];
}>;

export type BetsByInitialRoundIdQuery = {
  betsByInitialRoundId: Array<{
    id: string;
    reelSetId: string;
    result: { reelPositions: Array<number | null>; winCoinAmount: number };
  }> | null;
};

export type IsStoppedQueryVariables = Exact<{ [key: string]: never }>;

export type IsStoppedQuery = { isSlotStopped: boolean };

export const HistoryNodeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'historyNode' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Bet' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'coinAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'coinValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reelSetId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'data' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'result' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'reelPositions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'winCoinAmount' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userBonus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'betId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bonusId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bonus' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coinAmount' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HistoryNodeFragment, unknown>;
export const BetCoinRewardFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'betCoinReward' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BetCoinReward' } },
      selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }] },
    },
  ],
} as unknown as DocumentNode<BetCoinRewardFragment, unknown>;
export const UserBonusFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userBonus' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserBonus' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'coinValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'coinAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'data' } },
          { kind: 'Field', name: { kind: 'Name', value: 'betId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalWinAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rounds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roundsPlayed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bonus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reelSetId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'coinAmount' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'bonusId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFreeBet' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserBonusFragment, unknown>;
export const BetBonusRewardFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'betBonusReward' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BetBonusReward' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'userBonusId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userBonus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userBonus' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userBonus' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserBonus' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'coinValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'coinAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'data' } },
          { kind: 'Field', name: { kind: 'Name', value: 'betId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalWinAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rounds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roundsPlayed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bonus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reelSetId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'coinAmount' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'bonusId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFreeBet' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BetBonusRewardFragment, unknown>;
export const ReplayBonusRewardFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'replayBonusReward' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ReplayBonusReward' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'userBonusId' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'userBonus' },
            name: { kind: 'Name', value: 'replayUserBonus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userBonus' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userBonus' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserBonus' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'coinValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'coinAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'data' } },
          { kind: 'Field', name: { kind: 'Name', value: 'betId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalWinAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rounds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roundsPlayed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bonus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reelSetId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'coinAmount' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'bonusId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFreeBet' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReplayBonusRewardFragment, unknown>;
export const AuthDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Auth' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'AuthInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auth' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'sessionId' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AuthMutation, AuthMutationVariables>;
export const PlaceBetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PlaceBet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'PlaceBetInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'placeBet' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bet' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'coinAmount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'coinValue' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slotId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userBonusId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'reelSetId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reelSet' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'layout' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lineSet' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'coinAmountMultiplier' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userBonus' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'bonus' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'reelSetId' } }],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'reelPositions' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'winCoinAmount' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paylines' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'lineId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'winPositions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'balance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'placed' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'settled' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rewards' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'betBonusReward' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'betCoinReward' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userBonus' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserBonus' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'coinValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'coinAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'data' } },
          { kind: 'Field', name: { kind: 'Name', value: 'betId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalWinAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rounds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roundsPlayed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bonus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reelSetId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'coinAmount' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'bonusId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFreeBet' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'betBonusReward' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BetBonusReward' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'userBonusId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userBonus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userBonus' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'betCoinReward' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BetCoinReward' } },
      selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }] },
    },
  ],
} as unknown as DocumentNode<PlaceBetMutation, PlaceBetMutationVariables>;
export const BuyBonusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'buyBonus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'BuyBonusesInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyBonus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bonusId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'coinValue' } },
                { kind: 'Field', name: { kind: 'Name', value: 'data' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BuyBonusMutation, BuyBonusMutationVariables>;
export const ConfigDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Config' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isTurboSpin' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isSoundOn' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isSpinInProgress' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isSlotBusy' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isSlotStopped' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isOpenHistoryPopup' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isOpenBetSettingsPopup' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isOpenAutoplayPopup' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isOpenInfoPopup' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isHistoryVisible' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ConfigQuery, ConfigQueryVariables>;
export const GetUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUser' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'balance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserQuery, GetUserQueryVariables>;
export const ReplayGetUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'replayGetUser' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'balance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReplayGetUserQuery, ReplayGetUserQueryVariables>;
export const GetBetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetBet' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'betAmount' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetBetQuery, GetBetQueryVariables>;
export const GetWinDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetWin' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'winAmount' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetWinQuery, GetWinQueryVariables>;
export const GetAutoSpinsSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAutoSpinsSettings' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'autoSpinsAmount' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stopOnWinExceeds' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stopOnBalanceIncrease' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stopOnBalanceDecrease' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isStopOnAnyWin' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isStopOnFeatureWin' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isStopOnWinExceeds' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isStopOnBalanceIncrease' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isStopOnBalanceDecrease' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'autoSpinsStartBalance' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAutoSpinsSettingsQuery, GetAutoSpinsSettingsQueryVariables>;
export const GetAutoSpinDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAutoSpin' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isAutoSpins' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'autoSpinsLeft' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAutoSpinQuery, GetAutoSpinQueryVariables>;
export const UserBonusBetsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'userBonusBets' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'GetBetsInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bets' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserBonusBetsQuery, UserBonusBetsQueryVariables>;
export const ReplayBonusBetsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'replayBonusBets' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'GetBetsInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bets' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReplayBonusBetsQuery, ReplayBonusBetsQueryVariables>;
export const SlotHistoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'slotHistory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'GetBetsInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bets' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'historyNode' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'historyNode' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Bet' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'coinAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'coinValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reelSetId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'data' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'result' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'reelPositions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'winCoinAmount' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userBonus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'betId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bonusId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bonus' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coinAmount' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SlotHistoryQuery, SlotHistoryQueryVariables>;
export const ReplaySlotHistoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'replaySlotHistory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'GetBetsInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bets' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'historyNode' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'historyNode' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Bet' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'coinAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'coinValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reelSetId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'data' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'result' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'reelPositions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'winCoinAmount' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userBonus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'betId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bonusId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bonus' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coinAmount' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReplaySlotHistoryQuery, ReplaySlotHistoryQueryVariables>;
export const BetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'bet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'GetBetInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bet' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'coinAmount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'coinValue' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slotId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reelSetId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'reelPositions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'winCoinAmount' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userBonus' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'betId' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'data' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BetQuery, BetQueryVariables>;
export const GetSlotDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getSlot' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'GetSlotInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'slot' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'layout' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'lines' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'icons' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'startPosition' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'clientSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'coinAmounts' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'default' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'quick' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'coinValues' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'variants' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'autoplay' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'options' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'conditions' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'stopOnAnyWin' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'Field', name: { kind: 'Name', value: 'enabled' } }],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'stopIfFeatureIsWon' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'Field', name: { kind: 'Name', value: 'enabled' } }],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'stopIfSingleWinExceeds' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'multipliers' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'stopIfBalanceDecreasesBy' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'multipliers' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'stopIfBalanceIncreasesBy' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'multipliers' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lineSets' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lines' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'coinAmountMultiplier' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSlotQuery, GetSlotQueryVariables>;
export const GetBonusesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getBonuses' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'GetBonusesInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bonuses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'coinAmount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetBonusesQuery, GetBonusesQueryVariables>;
export const GetUserBonusesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserBonuses' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'GetUserBonusesInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userBonuses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userBonus' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userBonus' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserBonus' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'coinValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'coinAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'data' } },
          { kind: 'Field', name: { kind: 'Name', value: 'betId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalWinAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rounds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roundsPlayed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bonus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reelSetId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'coinAmount' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'bonusId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFreeBet' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserBonusesQuery, GetUserBonusesQueryVariables>;
export const GetReplayUserBonusesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getReplayUserBonuses' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'GetUserBonusesInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userBonuses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userBonus' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userBonus' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserBonus' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'coinValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'coinAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'data' } },
          { kind: 'Field', name: { kind: 'Name', value: 'betId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalWinAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rounds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roundsPlayed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bonus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reelSetId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'coinAmount' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'bonusId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFreeBet' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetReplayUserBonusesQuery, GetReplayUserBonusesQueryVariables>;
export const ReplayBetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'replayBet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'betId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'placeBet' },
            name: { kind: 'Name', value: 'replayRound' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'betId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'betId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bet' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'coinAmount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'coinValue' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slotId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userBonusId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'reelSetId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reelSet' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'layout' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lineSet' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'coinAmountMultiplier' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'userBonus' },
                        name: { kind: 'Name', value: 'replayUserBonus' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'bonus' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'reelSetId' } }],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'reelPositions' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'winCoinAmount' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paylines' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'lineId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'winPositions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'balance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'placed' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'settled' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rewards' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'replayBonusReward' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'betCoinReward' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userBonus' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserBonus' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'coinValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'coinAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'data' } },
          { kind: 'Field', name: { kind: 'Name', value: 'betId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalWinAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rounds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roundsPlayed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bonus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reelSetId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'coinAmount' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'bonusId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFreeBet' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'replayBonusReward' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ReplayBonusReward' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'userBonusId' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'userBonus' },
            name: { kind: 'Name', value: 'replayUserBonus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'userBonus' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'betCoinReward' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BetCoinReward' } },
      selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'amount' } }] },
    },
  ],
} as unknown as DocumentNode<ReplayBetQuery, ReplayBetQueryVariables>;
export const BetsByInitialRoundIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'betsByInitialRoundId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'initialRoundId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'betsByInitialRoundId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'initialRoundId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'initialRoundId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'reelPositions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'winCoinAmount' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'reelSetId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BetsByInitialRoundIdQuery, BetsByInitialRoundIdQueryVariables>;
export const IsStoppedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'IsStopped' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isSlotStopped' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<IsStoppedQuery, IsStoppedQueryVariables>;
